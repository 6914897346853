@font-face {
    font-family: 'Bebas Neue';
    src: url('../font/bebas-neue/BebasNeue-webfont.eot');
    src: url('../font/bebas-neue/BebasNeue-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/bebas-neue/BebasNeue-webfont.woff') format('woff'),
         url('../font/bebas-neue/BebasNeue-webfont.ttf') format('truetype'),
         url('../font/bebas-neue/BebasNeue-webfont.svg#BebasNeueRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Book';
    src: url('../font/bebas-neue/BebasNeue Book.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/source-sans-pro/SourceSansPro-SemiboldIt-webfont.eot');
    src: url('../font/source-sans-pro/SourceSansPro-SemiboldIt-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-sans-pro/SourceSansPro-SemiboldIt-webfont.woff') format('woff'),
         url('../font/source-sans-pro/SourceSansPro-SemiboldIt-webfont.ttf') format('truetype'),
         url('../font/source-sans-pro/SourceSansPro-SemiboldIt-webfont.svg#SourceSansProSemiboldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/source-sans-pro/SourceSansPro-Bold-webfont.eot');
    src: url('../font/source-sans-pro/SourceSansPro-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-sans-pro/SourceSansPro-Bold-webfont.woff') format('woff'),
         url('../font/source-sans-pro/SourceSansPro-Bold-webfont.ttf') format('truetype'),
         url('../font/source-sans-pro/SourceSansPro-Bold-webfont.svg#SourceSansProBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/source-sans-pro/SourceSansPro-BoldIt-webfont.eot');
    src: url('../font/source-sans-pro/SourceSansPro-BoldIt-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-sans-pro/SourceSansPro-BoldIt-webfont.woff') format('woff'),
         url('../font/source-sans-pro/SourceSansPro-BoldIt-webfont.ttf') format('truetype'),
         url('../font/source-sans-pro/SourceSansPro-BoldIt-webfont.svg#SourceSansProBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/source-sans-pro/SourceSansPro-Black-webfont.eot');
    src: url('../font/source-sans-pro/SourceSansPro-Black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-sans-pro/SourceSansPro-Black-webfont.woff') format('woff'),
         url('../font/source-sans-pro/SourceSansPro-Black-webfont.ttf') format('truetype'),
         url('../font/source-sans-pro/SourceSansPro-Black-webfont.svg#SourceSansProBlack') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/source-sans-pro/SourceSansPro-BlackIt-webfont.eot');
    src: url('../font/source-sans-pro/SourceSansPro-BlackIt-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-sans-pro/SourceSansPro-BlackIt-webfont.woff') format('woff'),
         url('../font/source-sans-pro/SourceSansPro-BlackIt-webfont.ttf') format('truetype'),
         url('../font/source-sans-pro/SourceSansPro-BlackIt-webfont.svg#SourceSansProBlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/source-sans-pro/SourceSansPro-ExtraLight-webfont.eot');
    src: url('../font/source-sans-pro/SourceSansPro-ExtraLight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-sans-pro/SourceSansPro-ExtraLight-webfont.woff') format('woff'),
         url('../font/source-sans-pro/SourceSansPro-ExtraLight-webfont.ttf') format('truetype'),
         url('../font/source-sans-pro/SourceSansPro-ExtraLight-webfont.svg#SourceSansProExtraLight') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/source-sans-pro/SourceSansPro-ExtraLightIt-webfont.eot');
    src: url('../font/source-sans-pro/SourceSansPro-ExtraLightIt-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-sans-pro/SourceSansPro-ExtraLightIt-webfont.woff') format('woff'),
         url('../font/source-sans-pro/SourceSansPro-ExtraLightIt-webfont.ttf') format('truetype'),
         url('../font/source-sans-pro/SourceSansPro-ExtraLightIt-webfont.svg#SourceSansProExtraLightItalic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/source-sans-pro/SourceSansPro-Light-webfont.eot');
    src: url('../font/source-sans-pro/SourceSansPro-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-sans-pro/SourceSansPro-Light-webfont.woff') format('woff'),
         url('../font/source-sans-pro/SourceSansPro-Light-webfont.ttf') format('truetype'),
         url('../font/source-sans-pro/SourceSansPro-Light-webfont.svg#SourceSansProLight') format('svg');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/source-sans-pro/SourceSansPro-LightIt-webfont.eot');
    src: url('../font/source-sans-pro/SourceSansPro-LightIt-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-sans-pro/SourceSansPro-LightIt-webfont.woff') format('woff'),
         url('../font/source-sans-pro/SourceSansPro-LightIt-webfont.ttf') format('truetype'),
         url('../font/source-sans-pro/SourceSansPro-LightIt-webfont.svg#SourceSansProLightItalic') format('svg');
    font-weight: lighter;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/source-sans-pro/SourceSansPro-Regular-webfont.eot');
    src: url('../font/source-sans-pro/SourceSansPro-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-sans-pro/SourceSansPro-Regular-webfont.woff') format('woff'),
         url('../font/source-sans-pro/SourceSansPro-Regular-webfont.ttf') format('truetype'),
         url('../font/source-sans-pro/SourceSansPro-Regular-webfont.svg#SourceSansProRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/source-sans-pro/SourceSansPro-It-webfont.eot');
    src: url('../font/source-sans-pro/SourceSansPro-It-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-sans-pro/SourceSansPro-It-webfont.woff') format('woff'),
         url('../font/source-sans-pro/SourceSansPro-It-webfont.ttf') format('truetype'),
         url('../font/source-sans-pro/SourceSansPro-It-webfont.svg#SourceSansProItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/source-sans-pro/SourceSansPro-Semibold-webfont.eot');
    src: url('../font/source-sans-pro/SourceSansPro-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-sans-pro/SourceSansPro-Semibold-webfont.woff') format('woff'),
         url('../font/source-sans-pro/SourceSansPro-Semibold-webfont.ttf') format('truetype'),
         url('../font/source-sans-pro/SourceSansPro-Semibold-webfont.svg#SourceSansProSemibold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url('../font/source-code-pro/SourceCodePro-Light-webfont.eot');
    src: url('../font/source-code-pro/SourceCodePro-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-code-pro/SourceCodePro-Light-webfont.woff') format('woff'),
         url('../font/source-code-pro/SourceCodePro-Light-webfont.ttf') format('truetype'),
         url('../font/source-code-pro/SourceCodePro-Light-webfont.svg#SourceCodeProLight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url('../font/source-code-pro/SourceCodePro-ExtraLight-webfont.eot');
    src: url('../font/source-code-pro/SourceCodePro-ExtraLight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-code-pro/SourceCodePro-ExtraLight-webfont.woff') format('woff'),
         url('../font/source-code-pro/SourceCodePro-ExtraLight-webfont.ttf') format('truetype'),
         url('../font/source-code-pro/SourceCodePro-ExtraLight-webfont.svg#SourceCodeProExtraLight') format('svg');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url('../font/source-code-pro/SourceCodePro-Regular-webfont.eot');
    src: url('../font/source-code-pro/SourceCodePro-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-code-pro/SourceCodePro-Regular-webfont.woff') format('woff'),
         url('../font/source-code-pro/SourceCodePro-Regular-webfont.ttf') format('truetype'),
         url('../font/source-code-pro/SourceCodePro-Regular-webfont.svg#SourceCodeProRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url('../font/source-code-pro/SourceCodePro-Semibold-webfont.eot');
    src: url('../font/source-code-pro/SourceCodePro-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-code-pro/SourceCodePro-Semibold-webfont.woff') format('woff'),
         url('../font/source-code-pro/SourceCodePro-Semibold-webfont.ttf') format('truetype'),
         url('../font/source-code-pro/SourceCodePro-Semibold-webfont.svg#SourceCodeProSemibold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url('../font/source-code-pro/SourceCodePro-Bold-webfont.eot');
    src: url('../font/source-code-pro/SourceCodePro-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/source-code-pro/SourceCodePro-Bold-webfont.woff') format('woff'),
         url('../font/source-code-pro/SourceCodePro-Bold-webfont.ttf') format('truetype'),
         url('../font/source-code-pro/SourceCodePro-Bold-webfont.svg#SourceCodeProBold') format('svg');
    font-weight: bolder;
    font-style: normal;
}
